@use '../abstracts/variables' as *;
@use "../abstracts/mixins" as *;
.strap-container {
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    padding: 1rem 2rem;
    p {
        font-size: $font-s-small;
    }
    @include xl {
        padding: 2.25rem 16.5rem;
        p {
            font-size: $font-s-medium;
        }
    }
}