@use 'variables' as *;


@mixin xs {
    @media(min-width: map-get($breakpoints, "xs")) {
        @content;
    }
}

@mixin sm {
    @media(min-width: map-get($breakpoints, "sm")) {
        @content;
    }
}

@mixin md {
    @media(min-width: map-get($breakpoints, "md")) {
        @content;
    }
}

@mixin lg {
    @media(min-width: map-get($breakpoints, "lg")) {
        @content;
    }
}

@mixin xl {
    @media(min-width: map-get($breakpoints, "xl")) {
        @content;
    }
}

@mixin rotate($degree)
{
    @if($mobile-nav-opened){
        transform: rotate($degree)
    }
    @else {
        transform: rotate(0)
    }
}

@mixin translateX($x)
{
    @if($mobile-nav-opened){
        transform: translateX($x);
    }
    @else{
        transform: translateX(0);
    }
}

@mixin display-using-opacity($opacity)
{
    @if($mobile-nav-opened){
        opacity: $opacity;
    }
    @else{
        opacity: 1;
    }
}

@mixin flag-icon($country) {
  .fi-#{$country} {
    @if $flag-icons-use-square {
      &.fis {
        background-image: url(#{$flag-icons-path}#{$flag-icons-square-path}/#{$country}.svg);
      }
    }
  }
}