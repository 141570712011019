// colors
$neutral: #0A0F29;
$surface-shade: #696F8C;
$surface-muted: #FAFCFF;
$surface-dim: #D7D9E4;
$surface: #ffffff;
$purple: #3F2578;
$golden: #FFC246;

// transparent
$transparent: transparent;

// gradients
$blue-gradient: linear-gradient(180deg, #93AAFC 0%, #FFF 100%);
$dark-gradient: linear-gradient(180deg, #696F8C 0%, #101321 100%);
// TODO add footer gradient

// text colors
$text-primary-color: $neutral;

// font family
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
$font-family-default: 'Montserrat', sans-serif;

// font wieghts
$font-w-bold: 700;
$font-w-semi-bold: 600;
$font-w-medium: 500;
$font-w-regular: 400;

// font sizes
$font-s-xlarge: 3.5rem; // 56px
$font-s-large: 2.5rem; // 40px
$font-s-medium: 1.5rem;//24px
$font-s-regular: 1.125rem; // 18px
$font-s-small: 1rem; // 16px

// sizes
$size-100: 0.5rem; //8px
$size-200: 1rem; //16px
$size-300: 1.5rem; //24px
$size-400: 2rem; //32px
$size-500: 2.5rem; //40px
$size-600: 3rem; //48px
$size-700: 3.5rem; //56px
$size-800: 4rem; //64px
$size-900: 5rem; // 80px
$size-1000: 10rem; // 160px


// spacing
$base-padding: 0.5rem;
$base-margin: 0.5rem;

// box-shadow
$elevation-1:  0px .25rem .5rem 0px rgba(0, 0, 0, 0.05);

// breakpoints
$breakpoints: (
  xs: 0,
  sm: 36em,
  md: 45em,
  lg: 50em,
  xl: 70em
);

$flag-icons-path: "../flags" !default;
$flag-icons-square-path: "/1x1" !default;
$flag-icons-use-square: true !default;

$max-width: 75rem;
$gap: 1.5rem;
$mobile-nav-opened: false;