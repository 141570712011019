@use '../abstracts/variables' as *;

.validated-number-text-field {
    input + span {
    padding-right: $size-200;
    }

    input:invalid + span::after {
    position: absolute;
    content: "✖";
    padding-left: 5px;
    color: #8b0000;
    }

    input:valid + span::after {
    position: absolute;
    content: "✓";
    padding-left: 5px;
    color: #009000;
    }
}