@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;


img {
    aspect-ratio: 1 / 1;
}

.img-hero {
    width: 80%;
    @include lg {
        aspect-ratio: 1/1;
        width: 100%;
        object-fit: cover;
    }
}

.dentists-img {
    aspect-ratio: 1/1;
    object-fit: cover;
}

.svg-container {
    min-width: 64px;
}