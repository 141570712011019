@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.flex-column-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.flex-row-container {
    display: flex;
    flex-direction: row;
    gap: $gap;
}

.flex-row-container-no-gap {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.full-width {
    width: 100%;
}
.scrollable-flex-row-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    overflow-x: auto;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    @include xl {
        justify-content: space-between;
    }
}