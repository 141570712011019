@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;
.footer-container{
    padding: 1rem 0;
    ul {
        cursor: pointer;
    }
}

.footer-section {
    border: none;
    @include lg {
        border-right: 1px solid $surface;
    }
}
.section-content {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    img {
        object-fit: scale-down;
        height: fit-content;
    }
    @include lg {
        padding: 0;
        align-items: center;
        flex-direction: row;
        img {
            object-fit: contain;
        }
    }
}
.no-border {
    border: none;
}