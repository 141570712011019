/**
* @desc         adds default text styles
* @requires     variables.scss
*/
@use '../abstracts/variables.scss' as *;
body {
  color: $neutral;
}

body,
input,
select,
textarea {
  font-family: $font-family-default;
}

// headings and paragraph
h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  font-family: $font-family-default;
}

h2 {
  font-size: $font-s-large;
  font-weight: $font-w-semi-bold;
}
h4,
h5,
h6 {
  font-size: $font-s-regular;
  font-weight: $font-w-semi-bold;
}

button {
    font-family: $font-family-default;
    font-size: $font-s-regular;
    font-weight: font-w-medium;
}
