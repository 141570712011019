section {
    scroll-behavior: smooth;
}

.hero-wrapper {
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
}