@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;
:root {
    --open: '0';
}
.button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    background-color: $purple;
    color: $surface;
    font-size: $font-s-small;
    font-weight: $font-w-medium;
    padding: .75rem 1.5rem;
    border-radius: .25rem;
    border: transparent;
    box-shadow: none;
    cursor: pointer;
    width: 100%;
    @include md {
        font-size: $font-s-regular;
    }
}

.burger-button {
    position: absolute;
    right: $size-300;
    background-color: transparent;
    border:0px;
    cursor: pointer;
    @include xl {
        display: none;
    }
}

.burger-button[data-state="closed"] :is(.top, .bottom) {
  animation: to-open-icon 1s forwards;
}

.burger-button[data-state="opened"] :is(.top, .bottom) {
  animation: to-close-icon 1s forwards;
}

.burger-button .hamburger {
  transition: rotate 800ms 100ms;
}

.burger-button[data-state="opened"] .hamburger {
  rotate: 1turn;
}

.burger-button .line.top {
    --rotation: -45deg;
    transform-origin: 60px 45px;
}

.burger-button .line.bottom {
    --rotation: 45deg;
    transform-origin: 63px 55px;
}

@keyframes to-close-icon {
  0% {
    stroke-dashoffset: 0;
  }
  40% {
    stroke-dashoffset: 79.9;
  }
  60% {
    stroke-dashoffset: 79.9;
    rotate: calc(var(--rotation));
  }
  100% {
    stroke-dashoffset: 0;
    rotate: var(--rotation);
  }
}
@keyframes to-open-icon {
  0% {
    stroke-dashoffset: 0;
    rotate: var(--rotation);
  }
  40% {
    stroke-dashoffset: 79.9;
    rotate: var(--rotation);
  }
  60% {
    stroke-dashoffset: 79.9;
  }
  100% {
    stroke-dashoffset: 0;
  }
}


// .burger-button {
//     position: absolute;
//     right: 1.5rem;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-around;
//     background: transparent;
//     cursor: pointer;
//     padding: 0;
//     gap: $size-100;
//     &:hover {
//         span {
//             background: $purple;
//         }
//     }
//     span {
//         width: 2rem;
//         height: 0.25rem;
//         background: black;
//         border-radius: 10px;
//         transition: all 0.3s linear;
//         position: relative;
//         transform-origin: 0px;
//     }
//     @include xl {
//         display: none;
//     }
// }
// .opened {
//     span{
//             &:first-child {
//                 rotate: 45deg
//                 // @include rotate(45deg);
//             }
//             &:nth-child(2) {
//                 opacity: 0;
//                 transform: translateX(20px);
//                 // @include display-using-opacity(1);
//                 // @include translateX(20px);
//             };
//             &:nth-child(3) {
//                 rotate: -45deg
//                 // @include rotate(-45deg);
//             };
//         }
// }
// .closed {
//     span{
//             &:first-child {
//                 transform: rotate(0);
//             }
//             &:nth-child(2) {
//                 opacity: 1;
//                 transform: translateX(0);
//                 // @include display-using-opacity(1);
//                 // @include translateX(20px);
//             };
//             &:nth-child(3) {
//                 transform: rotate(0);
//                 // @include rotate(-45deg);
//             };
//     }
// }

// }

.hide-mobile {
    @include xs {
        display: none;
    }
     @include sm {
        display: none;
    }
    @include lg {
        display: none;
    }
    @include xl {
        display: block;
    }
}