@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.testimonials-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 500px;
}

.testimonials-container{
    display: grid;
    grid-template-areas: "button-left info button-right";
    grid-template-columns:auto 1fr auto;
    text-align: center;
    align-items: center;
    transition: color 0.3s linear;
    @include md {
        font-size: $font-s-xlarge;
    }
}

.left-button {
    grid-area: button-left;
}

.right-button {
    grid-area: right-button;
}

.content {
    grid-area: info;
    display: block;
    animation: show 1s 100ms cubic-bezier(0.38, 0.97, 0.56, 0.76) forwards;
    // Prestate
    opacity: 0;
    // remove transform for just a fade-in
    transform: translateX(-100px);
    transform-origin: left;
    .hidden {
        display: none;
        visibility: hidden;
    }
}

@keyframes show {
  100% {
    opacity: 1;
    transform: none;
  }
}