@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.nav-wrapper {
    position: relative;
    display: grid;
    padding: 1rem 0;
    grid-template-areas: "logo nav button";
    gap: calmp($size-400, 5vw, $size-700);
    align-items: center;
}

.primary-navigation {
    grid-area: nav;
    display: none;
    padding: $size-700;
    inset: 7rem $size-400 auto;
    background-color: $surface;
    border-radius: $size-100;
    gap: $size-600;
    position: fixed;
    box-shadow: 0 0 0.75em rgb(0, 0, 0, 0.05);
    z-index: 10;
    @include xl {
        display: flex;
        background-color: transparent;
        position: unset;
        padding: 0;
        box-shadow: unset;
    }
}
body[data-overlay] {
    content: '';
    position: fixed;
    inset: 0;
    background-image: linear-gradient(rgb(0 0 0 / 0), rgb(0 0 0 / 0.8));
}

.nav-list {
    display: grid;
    gap: $size-400;
    text-align: center;
    font-weight: $font-w-semi-bold;
    :hover{
        color: $purple;
    }
    @include xl {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: $size-300;
        font-weight: $font-w-regular;
    }

}
.animate.pop {
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-duration: 0.5s;
}

@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.opened {
    display: block;
}

.logo {
    background: url('../../../public/logos/colored-purple-text/colored-purple-text-vertical-small.svg');
    grid-area: logo;
}
