@use 'variables' as *;
@use 'mixins' as *;

.text-primary {
    color: $neutral;
}

.text-primary-white {
    color: $surface;
}

.text-secondary {
    color: $surface-shade;
}

.text-accent {
    color: $purple;
}

.fs-100 {
    font-size: $font-s-small;
}

.fs-300 {
    font-size: $font-s-small;
    @include lg {
        font-size: $font-s-regular;
    }
}

.fs-500 {
    font-size: $font-s-regular;
    @include lg {
        font-size: $font-s-medium;
    }
}

.fs-700 {
    font-size: $font-s-large;
}

.fs-900 {
    font-size: $font-s-large;
    @include md {
        font-size: $font-s-xlarge;
    }
}

.fw-400 {
    font-weight: $font-w-regular;
}

.fw-500 {
    font-weight: $font-w-medium;
}

.fw-600 {
    font-weight: $font-w-semi-bold;
}

.fw-700 {
    font-weight: $font-w-bold;
}


.bg-primary {
    background: $blue-gradient;
}

.bg-dark-gradient {
    background: $dark-gradient;
}

.bg-white {
    background-color: $surface;
}

.bg-purple{
    background-color: $purple;
}

.bg-dimmed {
    background-color: $surface-dim;
}

.bg-muted {
    background-color: $surface-muted;
}

.items-aligned {
    align-items: center;
}

.curved {
    border-radius: $size-100;
}

.text-centred {
    text-align: center;
}

.line-height-100 {
    line-height: 1;
}

.line-height-300 {
    line-height: 1.2;
}

.line-height-700 {
    line-height: 1.6;
}

.fixed-height {
    height: $size-200;
}

.self-center {
    justify-self: center;
}
.padding-sm {
    padding: $size-100;
}
.padding-md {
    padding: $size-200;
    @include md {
        padding: $size-500;
    }
}

.padding-bottom-md {
    padding-bottom: $size-200;
}

.mobile-only-padding-bottom-md {
    padding-bottom: $size-200;
    @include md {
        padding-bottom: 0;
    }
}

.padding-bottom-lg {
    padding-bottom: $size-500;
}

.mobile-only-padding-bottom-lg {
    padding: 0;
    @include md {
        padding-bottom: $size-500;
    }

}
.padding-bottom-xlg {
    padding-bottom: $size-700;
}
.fs-italic {
    font-style: italic;
}
.flex-start {
    align-items: flex-start;
}

.text-balance {
    text-wrap: balance;
}

.flex-center {
    align-items: center;
}

.content-flex-center {
    display: flex;
    justify-content: center;
}


@media(max-width: 36em) {
    .fs-700 {
        font-size: $font-s-medium;
    }
}

.mobile-only {
    display: block;
    @include md {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
        display: none;
    }
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
    display: none;
}

.animate-hidden {
    animation: fadeBottom 1s ease-in-out;
    animation-fill-mode: both;
}


@keyframes fadeBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}