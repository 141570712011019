@use "sass:math";
@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

$grid-colums: 12;

.grid-responsive {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: 1fr;
    @include xl {
        grid-auto-flow: column;
    }
}

.even-columns {
    display: grid;
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
    gap: $gap;
    @include lg {
        grid-auto-flow: column;

    }
}

.ortho-custom-grid {
    display: grid;
    gap: $gap;
    grid-template-areas:
        "ortho-1"
        "img-1"
        "ortho-2"
        "img-2"
        "ortho-3";
    @include lg {
        grid-template-areas:
        "ortho-1 img-1"
        "ortho-1 img-1"
        "ortho-1 img-1"
        "ortho-2 img-1"
        "ortho-2 img-1"
        "ortho-2 img-2"
        "ortho-3 img-2"
        "ortho-3 img-2"
    }

}

.img-1 {
    grid-area: img-1;
}

.img-2 {
    grid-area: img-2;
    width:100%;
    height:100%;
}

.ortho-1 {
    grid-area: ortho-1;
}

.ortho-2 {
    grid-area: ortho-2;
}

.ortho-3 {
    grid-area: ortho-3;
}

.columns-70-30 {
    display: grid;
    grid-auto-flow: row;
    gap: $gap;
    @include lg {
        grid-template-columns: 60% 40%;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
    }
}

.columns-70-30 {
    display: grid;
    grid-auto-flow: row;
    gap: $gap;
    @include lg {
        grid-template-columns: 70% 30%;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
    }
}

//base layout classes
.container {
    width: min($max-width, 100% - 2rem);
    margin-inline: auto;
}

.main-content {
    min-height: 100vh;
    min-height: 100dvh;
    display: grid;
    grid-template-rows: auto 1fr auto;
}

.flex-row {
    display: flex;
    flex-flow: row wrap;
}

// col classes
@include xs {
    @for $i from 1 through $grid-colums {
        .col-#{$i}-xs {
            box-sizing: border-box;
            flex-grow: 0;
            width: math.div($i * 100, $grid-colums);
            flex: 0 0 (100% / $grid-colums * $i);
            max-width: (100% / $grid-colums * $i);
        }
    }
}

@include sm {
    @for $i from 1 through $grid-colums {
        .col-#{$i}-sm {
            box-sizing: border-box;
            flex-grow: 0;
            width: math.div($i * 100, $grid-colums);
            flex: 0 0 (100% / $grid-colums * $i);
            max-width: (100% / $grid-colums * $i);
        }
    }
}

@include md {
    @for $i from 1 through $grid-colums {
        .col-#{$i}-md {
            box-sizing: border-box;
            flex-grow: 0;
            width: math.div($i * 100, $grid-colums);
            flex: 0 0 (100% / $grid-colums * $i);
            max-width: (100% / $grid-colums * $i);
        }
    }
}

@include lg {
    @for $i from 1 through $grid-colums {
        .col-#{$i}-lg {
            box-sizing: border-box;
            flex-grow: 0;
            width: math.div($i * 100, $grid-colums);
            flex: 0 0 (100% / $grid-colums * $i);
            max-width: (100% / $grid-colums * $i);
        }
    }
}

@include xl {
    @for $i from 1 through $grid-colums {
        .col-#{$i}-xl {
            box-sizing: border-box;
            flex-grow: 0;
            width: math.div($i * 100, $grid-colums);
            flex: 0 0 (100% / $grid-colums * $i);
            max-width: (100% / $grid-colums * $i);
        }
    }
}