@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.card-container {
    display: grid;
    min-width: 15em;
    max-width: 15em;
    background: $surface;
    border-radius: 0.5rem;
    text-align: center;
    user-select: none;
    grid-template-rows: 60% 30% 10%;
}

.img-title-container {
    position: relative;
    img {
        border-radius: 0.5rem 0.5rem 0 0;
        width: 100%;
        height: 100%;
    }
}

.card-title {
    bottom: 0;
    position: absolute;
    padding: $size-100;
    text-align: center;
    width: 100%;
    opacity: 0.5;
    background: $neutral;
    color: $surface;
    font-weight: $font-w-semi-bold;
    font-size: $font-s-small;
    @include md {
        font-size: $font-s-medium;
    }
}

.card-content {
    padding: $size-200 $size-100;
    font-size: $font-s-small;
    font-weight: $font-w-semi-bold;
    height: 7em;
    @include md {
        font-size: $font-s-medium;
        padding: $size-400 $size-200;
    }
}